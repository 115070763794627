import React, { useEffect } from "react";

import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { VisualiserContext } from "pages/Visualiser/context.js";
import { useContext } from "react";
import { PAGES } from "pages/config.js";

const Text = styled.a`
  cursor: pointer;
`;

export default function TermsOfUse() {
  const [showModal, setShowModal] = React.useState(true);
  const { index, apiPostFix } = useContext(VisualiserContext);
  const {
    TermsOfUsestbTag,
    TermsOfUseTitle,
    name,
    TermsOfUseLink,
  } = PAGES[index];

  useEffect(() => {
    setShowModal(true);
   }, [apiPostFix]);

  return (
    <>
      <Text onClick={() => setShowModal((showModal) => !showModal)}>
        Show terms of use
      </Text>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="h5">{TermsOfUseTitle} - Terms of Use</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This data can be used by third parties as long as the source is
            clearly attributed to {TermsOfUsestbTag}, a logo is added to any
            graphs and if online, a link is added back{" "}
            <a href={TermsOfUseLink} target="_blank" rel="noopener noreferrer">
              to this {TermsOfUsestbTag} webpage
            </a>
            . For our terms of use, please see the{" "}
            <a
              href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Government Licence
            </a>
            . Use of the {name} EV Charging Infrastructure regional requirements
            tool also indicates your acceptance of{" "}
            <a
              href="https://transportforthenorth.com/wp-content/uploads/Final-version-Disclaimer-and-Appropriate-Use-EVCI-04062024.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              this Disclaimer and Appropriate Use Statement
            </a>
            .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
